import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Utils/seo"

export default function About(props) {
	return (
		<Layout>
			{/* siteTitle specifies that the title will the siteTitle alone */}
			<SEO title="siteTitle" />
			<section>
				<h1>Books I've Authored</h1>
				<h4>I'm working on a fictional novel.. it's in progress.</h4>
			</section>
		</Layout>
	)
}
